import { LoaderFunctionArgs, json } from "@remix-run/node";
import { Outlet, isRouteErrorResponse, useRouteError } from "@remix-run/react";
import Footer from "~/components/Footer";
import Nav from "~/components/Nav";
import NotFoundSection from "~/components/NotFoundSection";
import { i18nCookie } from "~/utils/cookies.server";
import i18next from "~/utils/localization/i18next.server";

export async function loader({ request }: LoaderFunctionArgs) {
  let locale = await i18next.getLocale(request);
  return json({ locale });
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFoundSection />;
    }

    // throw new Error(`${error.status} ${error.statusText}`);
  }

  throw new Error(error instanceof Error ? error.message : "Unknown Error");
};

export default function Layout() {
  return (
    <div className="flex min-h-screen flex-col items-stretch">
      <Nav />
      <div className="flex-1">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
